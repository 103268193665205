// extracted by mini-css-extract-plugin
export var check = "configurator-checkbox-module--check--0A0ay";
export var container = "configurator-checkbox-module--container--EJb0g";
export var description = "configurator-checkbox-module--description--KXGoZ";
export var header = "configurator-checkbox-module--header--QaPom";
export var iconBox = "configurator-checkbox-module--icon-box--UX1ne";
export var input = "configurator-checkbox-module--input--eus7h";
export var label = "configurator-checkbox-module--label--mnPS9";
export var link = "configurator-checkbox-module--link--S4es1";
export var nameText = "configurator-checkbox-module--name-text--AJmEi";
export var open = "configurator-checkbox-module--open--qu0U7";
export var toggleBox = "configurator-checkbox-module--toggle-box--yZtSS";