import * as Yup from 'yup';

import { IConsent } from '../models/consent';
import { IConfiguratorFormValues } from '../models/configurator-form.model';
import { IPacket, IPacketModule } from '../models/packet.model';
import { useT } from '../hooks/use-translation';
import { getContactFormFieldsSchema, getInitialContactFormValues } from './contact.form';

export function getInitialConfiguratorFormValues(
    consents: IConsent[],
    modules: IPacketModule[],
    packet: IPacket | undefined
): IConfiguratorFormValues {
    const values = {
        ...getInitialContactFormValues(consents, ''),
        modules: getConfiguratorModulesFieldValue(modules, packet),
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete values.url;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete values.context;
    return values;
}

export function getConfiguratorModulesFieldValue(
    modules: IPacketModule[],
    packet: IPacket | undefined
) {
    return modules.reduce((acc, module) => {
        acc[module.moduleId] =
            packet?.modules
                .find((bundleModle) => {
                    return bundleModle.moduleId === module.moduleId;
                })
                ?.functionalityIds.map((id) => id.toString()) || [];
        return acc;
    }, {} as Record<string, string[]>);
}

export function getConfiguratorFormSchema(t: ReturnType<typeof useT>['t'], consents: IConsent[]) {
    return Yup.object().shape({
        ...getContactFormFieldsSchema(t, consents),
    });
}

export function getSelectedCount(values: IConfiguratorFormValues, module: IPacketModule) {
    return module.functionalities.reduce((acc, functionality) => {
        if (values.modules[module.moduleId].includes(functionality.functionalityId.toString())) {
            acc++;
            return acc;
        }
        return acc;
    }, 0);
}
