// extracted by mini-css-extract-plugin
export var active = "configurator-module--active--8ewpa";
export var aside = "configurator-module--aside--42JDX";
export var asideButton = "configurator-module--aside-button--I3are";
export var asideLabel = "configurator-module--aside-label--p9NfB";
export var asideList = "configurator-module--aside-list--4gy7I";
export var card = "configurator-module--card--J4Vww";
export var contactFields = "configurator-module--contact-fields--HivwG";
export var desktop = "configurator-module--desktop--TzTi1";
export var errorText = "configurator-module--error-text--yrwTt";
export var form = "configurator-module--form--jxa1b";
export var functionalities = "configurator-module--functionalities--SGRyx";
export var item = "configurator-module--item--41eZY";
export var layout = "configurator-module--layout--g4PHm";
export var list = "configurator-module--list--K-yzk";
export var loading = "configurator-module--loading--VuxtP";
export var logo = "configurator-module--logo--ggHi5";
export var mobile = "configurator-module--mobile--cLOow";
export var moduleList = "configurator-module--module-list--cGPFi";
export var moreBox = "configurator-module--more-box--zyDW8";
export var moreIcon = "configurator-module--more-icon--1H8K6";
export var noPacket = "configurator-module--no-packet--Day5Z";
export var packetHeader = "configurator-module--packet-header--RDyi5";
export var packetName = "configurator-module--packet-name--QyJ3c";
export var packetSee = "configurator-module--packet-see--lSYE0";
export var section = "configurator-module--section--eFxeg";
export var sectionBreadcrumbs = "configurator-module--section-breadcrumbs--+90wo";
export var sectionConfigurator = "configurator-module--section-configurator--kxQ8z";
export var sectionLogo = "configurator-module--section-logo--hhgUl";
export var selectedLabel = "configurator-module--selected-label--j56UJ";
export var submitButton = "configurator-module--submit-button--4Lx0A";
export var successMessage = "configurator-module--success-message--qu5vS";