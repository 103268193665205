import React from 'react';

import {
    container,
    content,
    name,
    text,
    label,
    buttonBox,
    price,
    button,
    selected,
    bestseller,
} from './packet-card.module.scss';
import { IPacket } from '../../models/packet.model';
import { useT } from '../../hooks/use-translation';

import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

export interface IPackageCardProps {
    className?: string;
    packet: IPacket;
    onPacketSelect?(packet: IPacket): void;
    isSelected?: boolean;
}

const PacketCard: React.FC<IPackageCardProps> = ({
    className = '',
    packet,
    onPacketSelect,
    isSelected = false,
}) => {
    const { t } = useT();

    const handlePacketSelect = () => {
        if (typeof onPacketSelect === 'function') {
            onPacketSelect(packet);
        }
    };

    return (
        <div
            className={`${container} ${className} ${isSelected ? selected : ''} ${
                packet.isBestseller ? bestseller : ''
            }`}
        >
            {packet.isBestseller && <p className={label}>{t('packet.card.bestseller')}</p>}
            <div className={content}>
                <h2 className={name}>{packet.name}</h2>
                <Markdown className={text}>{packet.description}</Markdown>
                {packet.displayPrice && <p className={price}>{packet.displayPrice}</p>}
            </div>
            {packet.modules.some((module) => module.functionalityIds.length > 0) && (
                <div className={buttonBox}>
                    <Button
                        className={button}
                        onClick={handlePacketSelect}
                        color="light"
                        size="small"
                    >
                        {t('packet.card.button')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default PacketCard;
