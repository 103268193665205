import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import axios from 'axios';

import {
    layout,
    section,
    sectionLogo,
    sectionConfigurator,
    list,
    item,
    card,
    moreBox,
    moreIcon,
    form,
    aside,
    asideList,
    asideButton,
    active,
    functionalities,
    packetHeader,
    packetName,
    packetSee,
    asideLabel,
    selectedLabel,
    contactFields,
    submitButton,
    noPacket,
    desktop,
    mobile,
    moduleList,
    logo,
    sectionBreadcrumbs,
    errorText,
    successMessage,
    loading,
} from './configurator.module.scss';
import Logo from '../assets/images/svg/alterpage-logo.svg';
import ArrowIcon from '../assets/images/svg/arrow-up-right.svg';
import { IPacket, IPacketModule } from '../models/packet.model';
import { IConfiguratorFormValues } from '../models/configurator-form.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import {
    getConfiguratorFormSchema,
    getConfiguratorModulesFieldValue,
    getInitialConfiguratorFormValues,
    getSelectedCount,
} from '../formik/configurator.form';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';
import { handleFormikErrors, TAlterpressAxiosError } from '../utils/get-form-errors';
import { statusMap } from '../config/status';
import { consents } from '../formik/contact.form';
import { endpoints } from '../config/endpoints';

import MainLayout from '../layouts/main-layout';
import Section from '../components/hoc/section';
import PacketCard, { IPackageCardProps } from '../components/molecules/packet-card';
import ConfiguratorCheckbox from '../components/atoms/configurator-checkbox';
import Input from '../components/atoms/input';
import InputFile from '../components/atoms/input-file';
import Markdown from '../components/hoc/markdown';
import Consents from '../components/molecules/consents';
import Button from '../components/atoms/button';
import HandleFormikChange from '../components/hoc/handle-formik-change';
import ConfiguratorModule from '../components/molecules/configurator-module';
import Breadcrumbs from '../components/molecules/breadcrumbs';

interface IConfiguratorPageProps {
    readonly data: {
        allPacket: IQueryAllResult<IPacket>;
        allPacketModule: IQueryAllResult<IPacketModule>;
    };
}

const ConfiguratorPage: React.FC<IConfiguratorPageProps> = ({ data }) => {
    const { t } = useT();
    const { allPacket, allPacketModule } = data;
    const packets = getNodes(allPacket);
    const modules = getNodes(allPacketModule);

    const customPacket = packets.find((packet) =>
        packet.modules.every((module) => module.functionalityIds.length === 0)
    );

    const formikRef = useRef<FormikProps<IConfiguratorFormValues>>(null);
    const successMessageRef = useRef<HTMLParagraphElement>(null);
    const [activePacket, setActivePacket] = useState<IPacket | undefined>();
    const [activeModule, setActiveModule] = useState<IPacketModule | undefined>(modules[0]);
    const [isMessageSent, setIsMessageSent] = useState(false);

    const handlePacketSelect: IPackageCardProps['onPacketSelect'] = (packet) => {
        setActivePacket(packet);
        if (!formikRef.current) return;
        formikRef.current.setFieldValue(
            'modules',
            getConfiguratorModulesFieldValue(modules, packet)
        );
    };

    const handleModuleSelect = (module: IPacketModule) => {
        return () => {
            setActiveModule(module);
        };
    };

    const handleChange = (values: IConfiguratorFormValues) => {
        const selectedFunctionalities = Object.values(values.modules).flat();
        if (!selectedFunctionalities.length) {
            setActivePacket(undefined);
            return;
        }
        const newActivePacket = packets.find((packet) => {
            const packetFunctionalities = packet.modules
                .map((moduleBundle) => moduleBundle.functionalityIds)
                .flat()
                .map((id) => id.toString());
            return (
                packetFunctionalities.length === selectedFunctionalities.length &&
                packetFunctionalities.every((id) => selectedFunctionalities.includes(id))
            );
        });
        if (newActivePacket) {
            setActivePacket(newActivePacket);
        } else {
            setActivePacket(customPacket);
        }
    };

    const handleSubmit: FormikConfig<IConfiguratorFormValues>['onSubmit'] = async (
        values,
        helpers
    ) => {
        helpers.setStatus(statusMap.loading);
        if (!activePacket) {
            helpers.setStatus(statusMap.error);
            helpers.setFieldError('global', t('configurator.form.error.empty'));
            return;
        }
        const data = { ...values, packetId: activePacket.packetId };
        try {
            await axios.post(`${process.env.API_URL}${endpoints.alterpressConfigurator}`, data);
            helpers.resetForm();
            helpers.setStatus(statusMap.success);
            setIsMessageSent(true);
        } catch (err) {
            handleFormikErrors<IConfiguratorFormValues>(helpers, err as TAlterpressAxiosError, {
                global: t('form.error.global'),
                network: t('form.error.network'),
            });
            helpers.setStatus(statusMap.error);
        }
    };

    useEffect(() => {
        if (!successMessageRef.current) return;
        successMessageRef.current.scrollIntoView();
    }, [isMessageSent]);

    return (
        <MainLayout className={layout} showFloatingCircles={false}>
            <Section
                className={`${section} ${sectionBreadcrumbs}`}
                height="auto"
                theme="dark"
                circleDisplay="none"
                contentDisplay="left"
            >
                <Breadcrumbs breadcrumbs={[]} />
            </Section>
            <Section
                className={`${section} ${sectionLogo}`}
                height="auto"
                theme="dark"
                circleDisplay="none"
                contentDisplay="center"
            >
                <Logo className={logo} />
            </Section>
            <Section
                className={`${section} ${sectionConfigurator}`}
                theme="dark"
                circleDisplay="none"
                contentDisplay="center"
                gridType="wide"
                titleProps={{
                    weight: 'regular',
                    children: (
                        <>
                            <strong>{t('configurator.title.1')}</strong>
                            <br />
                            {t('configurator.title.2')}
                        </>
                    ),
                }}
                textProps={{ children: t('configurator.text') }}
            >
                <ul className={list}>
                    {packets.map((packet) => {
                        return (
                            <li className={item} key={`packet-item-${packet.packetId}`}>
                                <PacketCard
                                    className={card}
                                    packet={packet}
                                    isSelected={packet.packetId === activePacket?.packetId}
                                    onPacketSelect={handlePacketSelect}
                                />
                            </li>
                        );
                    })}
                </ul>
                <div className={moreBox}>
                    <p>{t('configurator.see')}</p>
                    <ArrowIcon className={moreIcon} />
                </div>
                <Formik
                    innerRef={formikRef}
                    initialValues={getInitialConfiguratorFormValues(
                        consents.normal,
                        modules,
                        activePacket
                    )}
                    validationSchema={getConfiguratorFormSchema(t, consents.normal)}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form
                            className={`${form} ${
                                formik.status === statusMap.loading ? loading : ''
                            }`}
                        >
                            <HandleFormikChange onChange={handleChange} />
                            <aside className={aside}>
                                <ul className={asideList}>
                                    {modules.map((module) => {
                                        const isActive = activeModule?.moduleId === module.moduleId;
                                        const count = module.functionalities.length;
                                        const selected = getSelectedCount(formik.values, module);
                                        return (
                                            <li key={`module-item-${module.moduleId}`}>
                                                <button
                                                    type="button"
                                                    className={`${asideButton} ${
                                                        isActive ? active : ''
                                                    }`}
                                                    onClick={handleModuleSelect(module)}
                                                >
                                                    <span>{module.name}</span>
                                                    {isActive && (
                                                        <span className={asideLabel}>
                                                            {selected}/{count}
                                                        </span>
                                                    )}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </aside>
                            <div className={`${functionalities} ${activePacket ? '' : noPacket}`}>
                                <div className={packetHeader}>
                                    <h2 className={packetName}>
                                        {activePacket?.name ||
                                            t('configurator.packet.name.fallback')}
                                    </h2>
                                    <p className={packetSee}>{t('configurator.see')}</p>
                                </div>
                                {activeModule && (
                                    <>
                                        <p className={`${selectedLabel} ${desktop}`}>
                                            {t('configurator.selected')}{' '}
                                            <span>
                                                {getSelectedCount(formik.values, activeModule)}
                                            </span>
                                        </p>
                                        <div className={desktop}>
                                            {activeModule.functionalities.map((functionality) => {
                                                return (
                                                    <ConfiguratorCheckbox
                                                        key={`checkbox-${functionality.functionalityId}`}
                                                        name={`modules.${activeModule.moduleId.toString()}`}
                                                        functionality={functionality}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </>
                                )}
                                <div className={`${mobile} ${moduleList}`}>
                                    {modules.map((module, index) => {
                                        return (
                                            <ConfiguratorModule
                                                key={`module-${module.moduleId}`}
                                                module={module}
                                                values={formik.values}
                                                isInitiallyOpen={index === 0}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={contactFields}>
                                <Input name="name" label={t('contact.form.name')} />
                                <Input name="email" type="email" label={t('contact.form.email')} />
                                <Input name="phone" type="tel" label={t('contact.form.phone')} />
                                <Input
                                    name="content"
                                    as="textarea"
                                    label={t('contact.form.content')}
                                />
                                <InputFile
                                    name="file"
                                    label={t('contact.form.file')}
                                    buttonProps={{ color: 'yellow', kind: 'normalDark' }}
                                />
                                <Markdown>{t('contact.form.info')}</Markdown>
                                <Consents consents={consents.normal} />
                                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                {/*@ts-ignore*/}
                                {formik.status === statusMap.error && formik.errors.global && (
                                    <>
                                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                                        {/*@ts-ignore*/}
                                        <p className={errorText}>{formik.errors.global}</p>
                                    </>
                                )}
                                {formik.status === statusMap.success && (
                                    <p className={successMessage} ref={successMessageRef}>
                                        {t('contact.form.success')}
                                    </p>
                                )}
                                <Button
                                    type="submit"
                                    color="yellow"
                                    kind="normalDark"
                                    className={submitButton}
                                >
                                    {t('contact.form.button.send')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Section>
        </MainLayout>
    );
};

export const query = graphql`
    query ($locale: String!) {
        allPacket(filter: { locale: { eq: $locale } }, sort: { fields: sequence, order: ASC }) {
            edges {
                node {
                    ...packetFields
                }
            }
        }
        allPacketModule(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...packetModuleFields
                }
            }
        }
    }
`;

export default ConfiguratorPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
