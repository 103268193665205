// extracted by mini-css-extract-plugin
export var bestseller = "packet-card-module--bestseller--OgT9m";
export var button = "packet-card-module--button--vp2-J";
export var buttonBox = "packet-card-module--button-box--JxVk1";
export var container = "packet-card-module--container--6OeO7";
export var content = "packet-card-module--content--WDNGs";
export var label = "packet-card-module--label--7+Hhz";
export var name = "packet-card-module--name--IGmC2";
export var price = "packet-card-module--price--PDasI";
export var selected = "packet-card-module--selected--cEwLp";
export var text = "packet-card-module--text--OrqW2";