import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Field } from 'formik';

import {
    container,
    label,
    header,
    input,
    check,
    iconBox,
    link,
    open,
    toggleBox,
    nameText,
    description,
} from './configurator-checkbox.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-left.svg';
import { IPacketModuleFunctionality } from '../../models/packet.model';
import { useT } from '../../hooks/use-translation';

import Markdown from '../hoc/markdown';

interface IConfiguratorCheckboxProps {
    className?: string;
    name: string;
    functionality: IPacketModuleFunctionality;
}

const ConfiguratorCheckbox: React.FC<IConfiguratorCheckboxProps> = ({
    className = '',
    name,
    functionality,
}) => {
    const { t } = useT();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={`${container} ${className} ${isOpen ? open : ''}`}>
            <button className={header} onClick={handleToggle}>
                <p className={nameText}>{functionality.name}</p>
                <div className={iconBox}>
                    <ArrowIcon />
                </div>
            </button>
            <label
                className={label}
                htmlFor={`functionality-${name}-${functionality.functionalityId}`}
            >
                <Field
                    id={`functionality-${name}-${functionality.functionalityId}`}
                    className={input}
                    type="checkbox"
                    name={name}
                    value={functionality.functionalityId.toString()}
                />
                <span className={check} />
            </label>
            <AnimateHeight height={isOpen ? 'auto' : 1}>
                <div className={toggleBox}>
                    <Markdown className={description}>{functionality.description}</Markdown>
                    {functionality.webUrl && (
                        <a
                            href={functionality.webUrl}
                            className={link}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('configurator.checkbox.link')}
                        </a>
                    )}
                </div>
            </AnimateHeight>
        </div>
    );
};

export default ConfiguratorCheckbox;
