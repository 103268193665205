import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import {
    container,
    open,
    name,
    button,
    iconBox,
    label,
    checkboxes,
} from './configurator-module.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-left.svg';
import { IPacketModule } from '../../models/packet.model';
import { IConfiguratorFormValues } from '../../models/configurator-form.model';

import ConfiguratorCheckbox from '../atoms/configurator-checkbox';
import { getSelectedCount } from '../../formik/configurator.form';

interface IConfiguratorModuleProps {
    className?: string;
    module: IPacketModule;
    values: IConfiguratorFormValues;
    isInitiallyOpen?: boolean;
}

const ConfiguratorModule: React.FC<IConfiguratorModuleProps> = ({
    className = '',
    module,
    values,
    isInitiallyOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={`${container} ${className} ${isOpen ? open : ''}`}>
            <button className={button} onClick={handleToggle}>
                <p className={name}>
                    {module.name}{' '}
                    {isOpen && (
                        <span className={label}>
                            {getSelectedCount(values, module)}/{module.functionalities.length}
                        </span>
                    )}
                </p>
                <div className={iconBox}>
                    <ArrowIcon />
                </div>
            </button>
            <AnimateHeight height={isOpen ? 'auto' : 1}>
                <div className={checkboxes}>
                    {module.functionalities.map((functionality) => {
                        return (
                            <ConfiguratorCheckbox
                                key={`checkbox-${functionality.functionalityId}`}
                                name={`modules.${module.moduleId.toString()}`}
                                functionality={functionality}
                            />
                        );
                    })}
                </div>
            </AnimateHeight>
        </div>
    );
};

export default ConfiguratorModule;
